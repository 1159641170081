import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function SectionChooseUs() {
    return (
        <div className="py-32 overflow-hidden bg-gray-100">
            <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
                <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Comment se déroule notre expertise fissures ?</h2>
                    <ul className="mt-6 text-xl leading-8 text-gray-600 list-decimal list-inside">
                        <li>
                        Décrivez-nous votre problématique par formulaire
                        </li>
                        <li>
                        Recevez un devis personnalisé par email
                        </li>
                        <li>
                        Notre devis est gratuit et sans engagement
                        </li>
                        <li>
                        En de confirmation, nous visitons le bien sinistré
                        </li>
                        <li>
                        L’expert sécheresse analyse les désordres (fissures, affaissement…)
                        </li>
                        <li>
                        Il vous remet son rapport d’expertise avec ses analyses et conclusion
                        </li>
                    </ul>

                    {/* <p className="mt-6 text-xl leading-8 text-gray-600">
                    Expertise Fissuration, c'est plus de 20 ans d'expérience au service de la sécurité de votre maison. Nos solutions personnalisées et notre engagement envers la satisfaction client, font de nous votre choix de confiance pour tous vos problèmes de fissuration.
                    </p> */}
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        <a
                            href="/accompagnement/expertise-secheresse/"
                            className="rounded-md bg-white border px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                        >
                        En savoir sur l’expertise sécheresse
                        </a>
                    </p>
                </div>
                <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                    <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                        <StaticImage
                            className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                            src="../../images/expert-fissure.jpg"
                            alt="Expert fissure"
                        />
                    </div>
                    <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                    <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                        <StaticImage
                            className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                            src="../../images/client-expertise.jpg"
                            alt="Client expertise"
                        />
                    </div>
                    <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                        <StaticImage
                            className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                            src="../../images/mise-en-relation-fissuration.jpg"
                            alt="Mise en relation"
                        />
                    </div>
                    <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                        <StaticImage
                            className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                            src="../../images/contact-expert.jpg"
                            alt="Contact expert"
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

    )
}