import React from "react"
import { Helmet } from "react-helmet"
import Banner from "../components/headers/banner"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import SectionHero from "../components/index/SectionHero"
import SectionService from "../components/index/SectionService"
import SectionChooseUs from "../components/index/SectionChooseUs"
import SectionAbout from "../components/index/SectionAbout"
import SectionBlog from "../components/index/SectionBlog"
import SectionCustomer from "../components/index/SectionCustomer"
import SectionFaq from "../components/index/SectionFaq"

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Expertise Sécheresse : Avis technique indépendant et Défense de vos intérêts</title>
        <meta name="description" content="Refus de prise en charge assurance, difficulté pour être indemnisé | Découvrez les meilleures stratégies pour les sinistres sécheresse, fissures et affaissement | Maisons et immeubles" />
        <link rel="canonical" href="https://www.expertise-secheresse.fr/" />
      </Helmet>
      <header>
        <Banner />
        <Header />
      </header>
      <main>
        <SectionHero />
        <SectionService />
        <SectionChooseUs />
        <SectionAbout />
        <SectionBlog />
        <SectionCustomer />
        <SectionFaq />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}
