/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react"


const categories = [
  {
    name: 'New Arrivals',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg',
  },
  {
    name: 'Productivity',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-02.jpg',
  },
  {
    name: 'Workspace',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-04.jpg',
  },
  {
    name: 'Accessories',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-05.jpg',
  },
  { 
    name: 'Sale', 
    href: '#', 
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-01-category-03.jpg' 
  },
  
]

const faqs = [
  {
    question: "What's the best thing about Switzerland?",
    answer:
      "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-12">
        <div className="mx-auto max-w-7xl ">
          <div className="px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0 lg:py-8">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Questions fréquentes sur l'expertise sécheresse</h2>
            {/* <a href="/faq/" className="hidden text-sm font-semibold text-amber-500 hover:text-amber-400 sm:block">
              Voir toutes les questions
              <span aria-hidden="true"> &rarr;</span>
            </a> */}
          </div>

          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Qu’est ce qu’une expertise sécheresse indépendante ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Une expertise sécheresse indépendante est une prestation de service portant sur les désordres consécutifs à des mouvements de terrain argileux. Elle est réalisée par un expert spécialisé dans les pathologies du bâtiment et en toute indépendance de l’assurance et des entreprises de travaux.</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Sur quoi porte une expertise sécheresse ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">L’expertise sécheresse concerne les causes des fissures (éléments principaux et déterminants et facteurs aggravants). Elle porte aussi sur l’évaluation des risques des fissures. Dans son rapport d’expertise, vous trouverez enfin des informations sur les travaux à réaliser (préconisations techniques de principe). </p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Sinistre déclaré à l’assurance : Pourquoi réaliser une expertise sécheresse ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Vous avez déclaré les fissures à l’assurance dommages/ouvrage décennale ou dans le cadre d'une catastrophe naturelle. Vous n'êtes pas contraint d'accepter les conclusions de l’expert d’assurance mandaté par votre assureur, ni l'offre d'indemnisation qui en découle. Vous avez le droit de demander une contre-expertise en cas de sécheresse et de fissures sur votre maison. Grâce à notre expertise sécheresse, vous obtenez un avis indépendant vous permettant d’aller “chercher” une indemnisation plus équitable.</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Dans quel contexte recourir à l’expertise sécheresse ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">L’expertise sécheresse peut être réalisée à la demande d’un propriétaire sinistré et dans plusieurs cas de figure. Le plus souvent, l’expertise sécheresse intervient pour appuyer votre démarche d’indemnisation auprès d’une assurance (décennale ou catastrophe naturelle). Mais vous pouvez aussi y avoir recours hors contexte d’assurance. Avant de faire les travaux et consulter des entreprises. Pour sécuriser un projet de vente immobilière ou au contraire d’achat.</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment se déroule une expertise sécheresse ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Il y a généralement 3 étapes : la visite du bien objet de l’expertise sécheresse, puis l’analyse de l’expert (causes des désordres, risques et identification de solutions) et enfin la remise du rapport d’expertise (avec observations et conclusions).</p>
              </dd>
            </div>
            <div className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
              <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">Comment constituer un dossier sécheresse pour l’assurance ?</dt>
              <dd className="mt-4 lg:col-span-7 lg:mt-0">
                <p className="text-base leading-7 text-gray-600">Il est intéressant de disposer d’un rapport d’expertise sur les causes des fissures et ce qu’il convient de faire en termes de travaux (mesures immédiates et mesures confortatives de moyen terme). Pour le suivi des évolutions des fissures, il est recommandé de prendre des photos des désordres et de relever régulièrement les valeurs des fissuromètres de suivi. Un fissuromètre est une jauge ou un témoin permettant de suivre les évolutions dans le temps de l’ouverture ou fermeture des fissures.</p>
              </dd>
            </div>
        </dl>


          {/* <dl className="mt-0 space-y-6 ">
              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment mesurer une fissure ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment surveiller une fissure ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Quelle est la définition d'un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment poser un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-7xl">
                <div class="md:flex">
                  <div class="md:shrink-0">
                    <StaticImage
                      className="h-48 w-full object-cover md:h-full md:w-48"
                      src="../../images/Jauge-G1-fissure-3.jpg" 
                      alt="Fissuration"
                    />
                  </div>
                  <div class="p-8 w-full">
                    <Disclosure as="div" className="pt-6">
                      {({ open }) => (
                        <>
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                              Comment utiliser un fissuromètre ?
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                            I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.
                            </p>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
          </dl> */}
        </div>
      </div>
    </div>
  )
}

  